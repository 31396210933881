import * as React from 'react';
import { Avatar, Box, Typography, Container } from 'components';
import {
  CloseIcon,
  LogoutIcon,
  MainIcon,
  MenuIcon,
  PageIcon,
  NotificationIcon,
  VideoIcon,
  TrainingIcon,
  WarningIcon
} from 'components/Icon';
import LogoSPIcon from 'components/Icon/LogoSPIcon';
import { forwardRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import RouteName from 'config/routes';
import { StyledLink, Nav, MobileFlex, MobileNav, MenuItem, Menu, StyledHeader } from './style';
import { useDispatch, useSelector } from 'react-redux';
import userSelector from 'selectors/userSelector';
import { resetAuthAction } from 'reducers/authReducer';

const Header = forwardRef(() => {
  const { t } = useTranslation();
  const location = useLocation();
  const [drawerShown, setDrawerShown] = useState(false);
  const user = useSelector(userSelector);
  const openDrawer = () => {
    setDrawerShown((prevState) => !prevState)
  }

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const logout = () => {
    dispatch(resetAuthAction());
    navigate(RouteName.login);
  }

  useEffect(() => {
    setDrawerShown(false);
  }, [location]);

  return (
    <StyledHeader>
      <Box
        component={Container}
        size="largest"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="0 24px"
      >
        <Box xs={{ display: 'none' }} md={{ display: 'flex' }}>
          <Link to={RouteName.mainMenu}>
            <LogoSPIcon width={260} height={40} />
          </Link>
        </Box>
        <MobileFlex>
          <Link to={RouteName.mainMenu}>
            <LogoSPIcon />
          </Link>
          <Box cursor="pointer" display="flex" alignItems="center">
            <MenuIcon color="black" onClick={openDrawer} />
          </Box>
        </MobileFlex>
        <Nav show={drawerShown}>
          <MobileFlex marginBottom={21}>
            <LogoSPIcon />
            <Box cursor="pointer" display="flex" alignItems="center">
              <CloseIcon color="black" onClick={openDrawer} />
            </Box>
          </MobileFlex>
          <Menu>
            {!user ? (
              <Box component={MenuItem} md={{ display: 'none' }} xs={{ borderBottom: 'none', paddingBottom: 12 }}>
                <StyledLink to={RouteName.login}>{t('common:link:login')}</StyledLink>
              </Box>
            ) : (
              <MenuItem>
                <Box xs={{ display: 'none' }} textAlign="center">
                  <Link to={RouteName.myPage}>
                    <Avatar size={20} src={user?.photo} alt="logo"></Avatar>
                    <Typography>{t('common:link:myPage')}</Typography>
                  </Link>
                </Box>
                <Box md={{ display: 'none' }} display="flex" alignItems="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center" marginRight={4}>
                    <Box display="flex" marginRight={15}>
                      <Avatar size={52} src={user?.photo} alt="logo"></Avatar>
                    </Box>
                    <Typography variant="description" clamp={2}>{user?.name}</Typography>
                  </Box>
                  <StyledLink to={RouteName.myPage}>{t('common:link:myPage')}</StyledLink>
                </Box>
              </MenuItem>
            )}
            <Box component={MenuItem} md={{ display: 'none' }}>
              <Link to={RouteName.mainMenu}>
                <MainIcon color="primary" />
                <Typography>{t('common:link:mainMenu')}</Typography>
              </Link>
            </Box>
            <MenuItem>
              <Link to={RouteName.training}>
                <TrainingIcon height={25} width={25} marginBottom={-2}/>
                <Typography>{t('common:link:training')}</Typography>
              </Link>
            </MenuItem>
            { !user?.company?.hidden_training_categories_screen &&
              <MenuItem>
                <Link to={RouteName.videoCategories}>
                  <VideoIcon  size="small" color="primary" />
                  <Typography>{t('common:link:video')}</Typography>
                </Link>
              </MenuItem>
            }
            {
              user &&
                <MenuItem>
                  <Link to={RouteName.notification}>
                    <NotificationIcon size="small" color="primary" />
                    <Typography>{t('common:link:notification')}</Typography>
                  </Link>
                </MenuItem>
            }
            <Box component={MenuItem} md={{ display: 'none' }}>
              <Link to={RouteName.termOfUse}>
                <PageIcon color="primary" />
                <Typography>{t('common:link:termOfUse')}</Typography>
              </Link>
            </Box>
            <Box component={MenuItem} md={{ display: 'none' }}>
              <Link to={RouteName.policy}>
                <WarningIcon color="primary" />
                <Typography>{t('common:link:policy')}</Typography>
              </Link>
            </Box>
            {user && (
              <MenuItem>
                <Box component="a" onClick={logout}>
                  <LogoutIcon size="small" color="primary" />
                  <Typography>{t('common:link:logout')}</Typography>
                </Box>
              </MenuItem>
            )}
          </Menu>
        </Nav>
        {drawerShown && (<MobileNav onClick={() => setDrawerShown(false)} />)}
      </Box>
    </StyledHeader>
  );
});

export default Header;
