import { forwardRef } from 'react';
import { NextIcon } from 'components/Icon';
import {Box, Link, Typography} from 'components';
import { StyledBreadcrumb, BreadcrumbContainer } from './style';
import RouteName from 'config/routes';
import { useSelector } from 'react-redux';
import userSelector from 'selectors/userSelector';

const Breadcrumb = forwardRef((props, ref) => {
  const { training } = props
  const user = useSelector(userSelector);

  return (
    <StyledBreadcrumb  {...props}>
      <BreadcrumbContainer size="small">
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={'6px'}>
          { training?.training_categories?.map((category, index) => {
            return <Box display={'flex'} flexDirection={'row'}>
              { index !== 0 && <Typography variant={'caption'} color={'default'}>、</Typography> }
              { category.hidden_from_user_category_listing || (user && user?.company?.hidden_training_categories_screen) ?
                (
                  <Typography variant={'caption'} color={'default'}>{category.name}</Typography>
                ) : (
                  <Link to={RouteName.videoList.replace(':categoryName', category.id)}>
                    <Typography variant={'caption'} color={'default'}>{category.name}</Typography>
                  </Link>
                )
              }
            </Box>
          })}
          <Box as={NextIcon} width={12} height={12} color="primary" />
          <Link to={RouteName.videoDetail.replace(':categoryName', training.training_category_id).replace(':videoName', training.id)} onClick={() => window.location.reload()}>
            <Typography variant={'caption'} color={'default'}>{training?.description}</Typography>
          </Link>
        </Box>
      </BreadcrumbContainer>
    </StyledBreadcrumb>
  );
});

export default Breadcrumb;
