import Default from 'apps/front/layouts/Default';
import * as React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUserQuery } from 'services/auth';
import RouteName from 'config/routes';
import { useSelector } from 'react-redux';
import userSelector from 'selectors/userSelector';
import notFoundSelector from 'selectors/notFoundSelector';
import { Loading } from 'components';

const ProtectedRoutes = () => {
  const location = useLocation();

  const { refetch, isFetching } = useUserQuery();
  const user = useSelector(userSelector)

  const notFound = useSelector(notFoundSelector);

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  if (notFound) {
    return <Navigate to={RouteName.notFound} />
  }

  if (user?.company?.hidden_training_categories_screen && location.pathname === RouteName.videoCategories) {
    return <Navigate to={RouteName.myPage} />
  }

  const redirectMypage = () => {
    if (!user?.password_editable && location.pathname === RouteName.changePassword) {
      return <Navigate to={RouteName.myPage} />
    }

    return false;
  }

  const redirectWelvieBoard = () => {
    const welvieBoardRoutes = [
      RouteName.questions,
      RouteName.questionCategories,
      RouteName.questionDetail,
      RouteName.questionPost,
      RouteName.questionEdit
    ];

    const hasWelvieBoardPermisison = user && user.company && user.company.has_welvie_board;
    if (welvieBoardRoutes.includes(location.pathname) && !hasWelvieBoardPermisison) {
      return <Navigate to={RouteName.notFound} />;
    }

    return false;
  }

  return isFetching
    ? <Loading isLoading={true} />
    : (
      user
        ? redirectMypage() || redirectWelvieBoard() || <Default />
        : <Navigate to={RouteName.login} />
    );
};

export default ProtectedRoutes;
